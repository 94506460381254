<template>
  <div>
    <section class="section container" v-show="show">
      <h1 v-if="finish" class="finish">
        <p>O tempo acabou :C, mas não se preocupe estamos enviando todas as questões que você respondeu :D!</p>
      </h1>

      <h1
        class="timer"
      >{{(10 > time.min ? '0' + time.min : time.min) + ':' + (10 > time.sec ? '0' + time.sec : time.sec) }}</h1>

      <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
      <!-- <h1>{{lesson.name}}</h1> -->
      <div v-if="exercices[0]">
        <p
          class="m-b-md"
        >Mínimo de {{exame.totalToApprove}}% de acertos para aprovação</p>
        <!-- choice  multiple_choice true_or_false -->

        <div>
          <div class="box" v-for="(exercice, index) in exercices" :key="exercice._id">
            <h1>{{"Questão " + parseInt(index+1)}}</h1>
            <p class="m-b-sm">{{exercice.title}}</p>
            <p class="m-b-sm">{{exercice.question}}</p>
            <div class="radio-content" v-for="choice in exercice.choice" :key="choice">
              <div v-if="exercice.type === 'choice'">
                <b-radio
                  :name="'item'+index"
                  v-model="answer[index]"
                  :native-value="choice"
                >{{choice}}</b-radio>
              </div>
              <div v-if="exercice.type === 'multiple_choice'">
                <b-checkbox
                  @input="addNewTodo($event, choice, index)"
                  :name="'item'+index"
                  :native-value="choice"
                >{{choice}}</b-checkbox>
              </div>
            </div>
            <div v-if="exercice.type === 'true_or_false'">
              <b-radio :name="'item'+index" v-model="answer[index]" :native-value="true">Verdadeiro</b-radio>
              <b-radio :name="'item'+index" v-model="answer[index]" :native-value="false">Falso</b-radio>
            </div>
          </div>
          <button class="button is-primary m-t-md" @click.once="question(false)">Responder</button>
        </div>
      </div>
    </section>

    <div class="modal-custom" v-if="showModal">
      <div>
        <img class="green-back" src="@/assets/img/topoModal.png" alt="green background" />
        <span v-if="result.approved && !lesson.finalLesson">PARABÉNS!</span>
        <span v-if="!result.approved">TENTE NOVAMENTE</span>
        <span
          class="span-custom"
          v-if="result.approved && lesson.finalLesson"
        >CURSO CONCLUÍDO!</span>
        <img v-if="!result.approved" class="pincel" src="@/assets/img/pencil.png" alt="Lapis" />
        <p>{{result.totalPercent+ '%'}}</p>
        <div class="columns" v-if="!result.approved">
          <div class="column custom">
            <router-link
              :to="{name: 'userCourseUser', params:{ name: $route.params.cur }}"
              tag="div"
            >
              <b-icon custom-class="icon" pack="fas" icon="list-ul"></b-icon>
              <span class="text">Aulas</span>
            </router-link>
          </div>
          <div class="column custom">
            <div @click="reload">
              <b-icon custom-class="icon" pack="fas" icon="redo-alt"></b-icon>
              <span class="text">Refazer</span>
            </div>
          </div>
        </div>
        <div v-else class="custom">
          <router-link :to="{name: 'userCourseUser', params:{ name: $route.params.cur }}" tag="div">
            <b-icon custom-class="icon" pack="fas" icon="angle-double-right"></b-icon>
            <span class="text">Continuar</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { isAuth } from "@/global/auth";
import { setInterval } from "timers";

export default {
  data() {
    return {
      finish: null,
      isLoading: true,
      module: [],
      exercices: [],
      answer: [],
      user: [],
      result: [],
      lesson: [],
      watch: [],
      exame: "",
      showModal: false,
      cronometer: true,
      finalized: false,
      show: true,
      time: {
        min: 0,
        sec: 0
      }
    };
  },
  beforeRouteLeave(to, from, next) {
    try {
      clearInterval(this.watch[0]._id);
      clearInterval(this.watch[1]._id);
      next();
    } catch (error) {
      next();
    }
  },
  methods: {
    token() {
      this.watch[1] = setInterval(() => {
        isAuth();
      }, 180 * 1000);
    },
    getExercice(question) {
      if (this.finalized) return;
      this.finalized = true;
      let req = !this.lesson.finalLesson
        ? Axios.get(
            `/v1/exame/lesson/${this.$route.params.name}?total=${question}`
          )
        : Axios.get(
            `/v1/exame/course/${this.$route.params.cur}?total=${question}`
          );
      req
        .then(data => {
          if (data.data) {
            this.exercices = data.data.data;
            this.isLoading = false;
          }
        })
        .catch(erro => {
          if (erro.response.data.message.includes("Insufficient")) {
            this.$buefy.dialog.confirm({
              title: "OOPS!",
              message: "Questões insuficientes ou nenhuma questão cadastrada!",
              onConfirm: () => {
                this.$router.push({
                  name: "userCourseUser",
                  params: { name: this.$route.params.cur }
                });
              }
            });
          }
        });
    },
    async postAnswer(dados) {
      try {
        const data = await Axios.post(`/v1/question-logs`, dados);
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async question(finalize) {
      clearInterval(this.watch[0]._id);
      if (!finalize) {
        this.isLoading = true;
        for (let [index, quest] of this.exercices.entries()) {
          this.$forceUpdate(this.answer);
          let dados = {
            user: this.user._id,
            course: this.$route.params.cur,
            question: quest._id,
            lesson: this.lesson._id,
            answer: [this.answer[index] ? this.answer[index] : 'null'],
            exam: this.exame._id
          };
          await this.postAnswer(dados);
        }
      }
      Axios.get(`/v1/exams/finalize/${this.exame._id}`)
        .then(data => {
          if (data.data) {
            this.result = data.data;
            if (!finalize) {
              this.isLoading = false;
              this.showModal = true;
              this.cronometer = false;
              this.finish = false;
            }
            // Axios.get(`/v1/status/${this.user._id}`)
            //   .then(data => {
            //     if (data.data) {
            //       console.log("status", data);
            //     }
            //   })
            //   .catch(erro => {
            //     console.error(erro);
            //   });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get("/v1/users/me")
        .then(data => {
          if (data.data) {
            this.user = data.data;
            this.getLesson();
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    addNewTodo(event, data, index) {
      let dado = this.answer[index] ? this.answer[index] : [];
      if (event) {
        dado.push([data]);
        this.answer[index] = dado;
      } else {
        let temp = this.answer[index];
        dado = temp.filter(title => data !== title[0]);
        this.answer[index] = dado;
      }
      this.$forceUpdate(this.answer);
    },
    getLesson() {
      Axios.get(`/v1/lessons/${this.$route.params.name}`)
        .then(data => {
          this.lesson = data.data;

          this.getModule();
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getModule() {
      Axios.get(`/v1/modules/${this.$route.params.mod}`)
        .then(data => {
          this.module = data.data;

          // let amountQuestion = this.lesson.amountQuestion
          //   ? this.lesson.amountQuestion
          //   : this.module.amountQuestion;

          // this.getExercice(amountQuestion);

          this.createExame();
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    createExame() {
      let data = {
        user: this.user._id,
        lesson: this.$route.params.name,
        course: this.$route.params.cur,
        module: this.$route.params.mod,
        finalExam: this.lesson.finalLesson
      };

      Axios.post("/v1/exams/", data)
        .then(data => {
          if (data.data) {
            this.exame = data.data.exam;
            this.timer(data.data.exam.examTime);
            this.exercices = data.data.questions;
            this.isLoading = false;
          }
        })
        .catch(erro => {
          console.error(erro);
          this.show = null;
          this.$buefy.dialog.alert({
            title: "OOPS!",
            message: erro.response.data.message,
            confirmText: "OK",
            onConfirm: () =>
              this.$router.push({
                name: "userCourseUser",
                params: {
                  name: this.$route.params.cur
                }
              })
          });
        });
    },
    timer(time) {
      this.time.min = time;
      if (this.cronometer) {
        this.watch[0] = setInterval(() => {
          if (this.time.sec <= 0) (this.time.sec = 60), this.time.min--;
          this.time.sec--;
          if (this.time.min < 0) {
            this.question();
            this.time.min = 0;
            this.time.sec = 0;
            clearInterval(this.watch[0]._id);
            this.finish = true;
          }
        }, 1000);
      }
    },
    reload() {
      location.reload();
    }
  },
  mounted() {
    this.getMe();
    this.token();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: bold;
  font-size: 1.1rem;
}

.finish {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999;
  background-color: #fff;
  padding: 20px;
  max-width: 30%;
}

.timer {
  position: fixed;
  right: 50px;
  font-size: 1.5rem;
  background: rgba(255, 252, 252, 0.8);
}

.radio-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.1rem;
  label {
    flex-direction: row;
    align-items: start;
    font-weight: bold;
    margin-bottom: 15px;
    line-height: 1;
  }
}
.modal-custom {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .green-back {
    margin-top: -18px;
  }

  .pincel {
    width: 40%;
    margin: 0 auto;
  }

  & > div {
    position: relative;
    min-width: 40%;
    min-height: 40%;
    background-color: #fff;
    box-shadow: 2px 0px 41px -13px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > span {
      position: absolute;
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%, 0);
      top: 5px;
      color: #fff;
      font-weight: bold;
      font-size: 1.7rem;
    }
    .span-custom {
      font-size: 1.2rem;
      top: 11px;
    }

    p {
      font-weight: bold;
      font-size: 5rem;
    }

    .custom {
      text-align: center;
      div {
        cursor: pointer;
        display: inline-block;
        .text {
          display: block;
        }
        .icon {
          border-radius: 50%;
          background-color: rgb(235, 42, 42);
          color: #fff;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
</style>
